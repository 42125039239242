import { React, useState } from 'react';
import { cn } from './cn';

function Thumbnail({img, img2, link, top, fit}) {
    const animatedImg = img2 ? img2 : img;
    return (
        <a href={link} className="">
            <div className="relative">
                <img 
                    src={img} 
                    className={
                    cn("h-[18.5rem] w-full rounded-md object-cover shadow-lg hover:opacity-90 duration-300", 
                    {"hover:opacity-0" : img2},
                    {"object-top" : top},
                    {"object-contain" : fit}
                    )} alt="">

                </img>

                {img2 ? <img src={animatedImg} className={
                    cn(`h-[18.5rem] w-full absolute top-0 left-0 opacity-0 rounded-md 
                    object-cover 
                    shadow-lg hover:opacity-100 duration-300`,
                     {"object-top" : top},
                     {"object-contain" : fit}
                     )} alt=""></img> : null}

                <img
                    src={img}
                    className={"object-cover absolute top-0 left-0 h-[18.5rem] w-full rounded-md -z-50 opacity-20"}
                    alt=""
                >
                </img>
            </div>
        </a>
    )
}


function CompactProject({
    imageList, 
    title,
    header,
    fit,
    link,
    italic,
    top}) {

    const [index, setIndex] = useState(0);
    const [image, setImage] = useState(imageList[index]);
    const img2 = imageList.length > 1 ? imageList[1] : null;

    return (
        <div className=" h-full" id="projectFX">
            <div className="">
                <div className="p-1">
                    <div className="p-1">
                        {imageList.length > 0 ? 
                        <Thumbnail 
                            img={image} 
                            link={link}
                            img2={img2}
                            top={top}
                            fit={fit}>
                        </Thumbnail>
                        : null}
                    </div>
                    <div className="p-1">
                        <span className="text-1xl font-semibold">{title}</span>
                        <div>
                            <span className={cn(`opacity-80`, {"italic" : italic})}>{header}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompactProject;