import {React} from 'react'
import CompactProject from "../components/compactProject";
import crabRig from "../images/art/crab_rig.png"
import awesomeRoom from "../images/art/awesomestRoom.png"
import tankGif from "../images/art/tankGif.gif"
import deth from "../images/art/d2.webp"
import galaxy from "../images/art/galaxy.webp"
import stickfighters from "../images/stickfighters.gif" 

function ArtProjects() {
    return (
        <div>
            <h1 className="mt-8">Games</h1>
            <div className=" w-full h-px mx-auto my-4 bg-black"></div>
            <div className="md:inline-grid md:grid-cols-2 items-center w-full">
                <CompactProject 
                    imageList={[tankGif]}
                    title="Couverture (2024, WIP)"
                    header="Unity, C#, HLSL"
                    link="https://pennupgrade.itch.io/chetrico">
                </CompactProject>
                <CompactProject 
                    imageList={[deth]}
                    title="Deth (2023)"
                    header="Unity, C#"
                    link="https://pennupgrade.com/games/deth">
                </CompactProject>
                <CompactProject 
                    imageList={[galaxy]}
                    title="Galaxy Gacha (2022)"
                    header="Unity, C#"
                    link="https://pennupgrade.com/games/galaxy-gacha">
                </CompactProject>
                <CompactProject 
                    imageList={[stickfighters]}
                    title="Stickfighters (2022)"
                    header="Java, LibGDX"
                    top={false}
                    link="https://github.com/geant04/stickfighters">
                </CompactProject>
            </div>
            
            <h1 className="mt-6">Art</h1>
            <div className=" w-full h-px mx-auto my-4 bg-black"></div>
            <div className="md:inline-grid md:grid-cols-2 items-center w-full">
                <CompactProject 
                    imageList={[crabRig]}
                    title="Crab Rig (2024)"
                    header="Unreal Engine 5, Maya"
                    fit={true}
                    link="">
                </CompactProject>
                <CompactProject 
                    imageList={[awesomeRoom]}
                    title="Lock In! (2023)"
                    header="Maya"
                    fit={true}
                    link="">
                </CompactProject>
            </div>
            <div className="mb-16"></div>
        </div>
    )
}

export default ArtProjects;
